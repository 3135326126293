import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Hello friends im using this website for more than 4 years. All the plans are very modern and. It also detect the right plan for our body condition. All the personal trainers in the sites are mostly good at their view. All time best personal trainers are krish gethin and gym stoppany. Their workout plans suots for all type of body and they provide plans for both size and for shredding. So if you want to buy any plan in this site go for those two plans. Hope you got some idea. Thankyou ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:' I think this is one of the best site according to my experience because I found a best result with the products of this site I use 4 types of different products.I found Each and every product is genuine and I would suggest to all of the people who want to purchase genuine supplement they can try this site I surely knew that anybody who would try this site should be happy because of its genuine products Everybody go with bodybuilding.com. Go with the best site.',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
